/* LAYOUT CSS */
.cover {
  background-color: $background-color;
  position: fixed;
  text-align: center;
  width: 100%;
  min-height: 400px;
  padding: 30px 15px 20px;
  z-index: 1;
  @include media-query($small-screen) {
    background-position: center;
    padding: 30px;
  }
  @include media-query($medium-screen) {
    height: 100vh;
    position: initial;
    width: 100%;
  }
}
.cover-card {
  color: $text-color;
  padding: 0 30px;
  .avatar {
    width: 150px;
    float: none;
  }
  .author_name {
    display: block;
    color: #6C3;
    font-size: 2.75em;
    font-weight: 800;
    text-transform: lowercase;
  }
  .author_job {
    display: block;
    color: $secondary;
    font-size: 0.8em;
    font-weight: 700;
    margin: -5px auto 10px;
  }
  .author_bio {
    font-size: 90%;
    display: block;
  }
  @include media-query($medium-screen) {
    text-align: right;
    margin: 0 auto;
    padding: 0 0px 80px;
  }
}
.social-links {
  margin-top: 0.5em;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 1em;
      a.social-link-item {
        color: $secondary;
        &:hover,
        &:focus {
          color: lighten($secondary, 14%);
        }
      }
    }
  }
}
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .nav-item {
    display: inline-block;
    *display: inline;
    zoom: 1;
    & a {
      margin-top: 1em;
      text-transform: lowercase;
      font-size: 85%;
      color: $secondary;
      &:hover,
      &:focus {
        color: lighten($secondary, 14%);
      }
    }
  }
}

.btn, .btn:visited, .btn:hover {
  background-color: #6C3;
  border-color: #6C3;
  color: #FFF;
}

.social-links ul li {
  margin-left: 0;
}

// Adding 'Contents' headline to the TOC
#markdown-toc::before {
  content: "Contents";
  font-weight: bold;
}


// Using numbers instead of bullets for listing
#markdown-toc ul {
  list-style: circle inside none;
}

#markdown-toc {
  border: 1px solid #aaa;
  padding: 0.75em;
  margin: 0;
  list-style: disc inside none;
  display: inline-block;
  font-weight: bold;
  background: #f5f5f5;
}

em.underline {
  text-decoration: underline;
}