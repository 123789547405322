/**
  * Grid
  */

// Container
.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@include media-query($medium-screen) {
  .container {
    width: 85%;
  }
}
@include media-query($large-screen) {
  .container {
    width: 70%;
  }
}
.container .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

// Section
.section {
	padding-top: 1rem;
	padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}

// Row and colum
.row {
  margin-left: auto;
  margin-right: auto;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    @include box-sizing(border-box);
    display:block;
  	vertical-align:top;
  	width:100%;

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        margin-left: 0;
      }
      $i: $i + 1;
    }
    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-s#{$i} {
        margin-left: $perc;
      }
      $i: $i + 1;
    }


    @include media-query($small-screen) {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          margin-left: 0;
        }
        $i: $i + 1;
      }
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} {
          margin-left: $perc;
        }
        $i: $i + 1;
      }

    }

    @include media-query($medium-screen) {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          margin-left: 0;
        }
        $i: $i + 1;
      }
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} {
          margin-left: $perc;
        }
        $i: $i + 1;
      }

    }

  }
}
